import * as Sentry from "@sentry/nextjs";
import { generateClient } from "aws-amplify/api";
import { getOperationAST } from "graphql";
import { useRouter } from "next/router";
import { useContext, useRef } from "react";
import { useQuery as reactQuery } from "react-query";
import Utils from "../core/Utils";
import { AppContext } from "../ctx/AppProvider";

import type { GraphQLResult } from "@aws-amplify/api-graphql";
import type { Scope } from "@sentry/nextjs";

const client = generateClient();

type QueryState<Data> = {
  data: Data;
  isLoading: boolean;
  isFetching: boolean;
  error: any;
};

type DefaultVariables = {
  [name: string]: any;
};

// TODO Extend the hook to use auto-pooling (on line: 50) on windows focus on a particular query call.
// this will avoid a lot of extra code within pages/components.
export const useQuery = <
  Data,
  QueryOperation = unknown,
  Variables extends DefaultVariables = DefaultVariables,
>(
  query?,
  variables?: Variables,
  dependencies = [],
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void,
): QueryState<Data> => {
  const router = useRouter();
  const store = useContext(AppContext);
  const operation = query ? getOperationAST(query) : null;
  const queryName = operation !== null ? operation.name.value : null;
  const queryKeys = queryName ? [queryName, variables, ...dependencies] : null;
  const queryError = useRef({});

  // @see: https://tkdodo.eu/blog/practical-react-query
  // @see: https://tkdodo.eu/blog/effective-react-query-keys
  // @see: https://tanstack.com/query/latest/docs/react/guides/query-keys?from=reactQueryV3&original=https%3A%2F%2Freact-query-v3.tanstack.com%2Fguides%2Fquery-keys
  const {
    data: queryData,
    isLoading,
    isFetching,
    error,
  } = reactQuery<QueryOperation, unknown, Data, any>({
    queryKey: queryKeys,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    // @see: https://github.com/TanStack/query/issues/1196
    enabled: !!query,
    queryFn: async ({ queryKey }) => {
      const [queryName] = queryKey;

      try {
        const { data } = (await client.graphql({
          query,
          variables,
        })) as GraphQLResult<Data>;

        if (error) {
          // group errors together based on their request and response
          const userEmail = store.get()?.amplifyUser?.email;
          const clientRef = store.get()?.activeClient?.cli_reference;
          Sentry.withScope((scope: Scope) => {
            scope.setFingerprint(queryKeys);
            scope.setExtra("variables", JSON.stringify(variables));
            scope.setExtra("user", userEmail);
            scope.setExtra("client_reference", clientRef);
            scope.setExtra("api response", JSON.stringify(error));
            Sentry.captureException(new Error(`GraphQL Error: ${queryName}`));
          });
          queryError.current = error;
        }

        onSuccess?.(data[queryName as string]);

        return data[queryName as string];
      } catch (reason) {
        console.log("🚀 ~ queryFn: ~ reason:", reason);
        const userEmail = store.get()?.amplifyUser?.email;
        const clientRef = store.get()?.activeClient?.cli_reference;

        // group errors together based on their request and response
        Sentry.withScope((scope: Scope) => {
          scope.setFingerprint(queryKeys);
          scope.setExtra("variables", JSON.stringify(variables));
          scope.setExtra("user", userEmail);
          scope.setExtra("client_reference", clientRef);
          scope.setExtra("api response", JSON.stringify(reason));
          Sentry.captureException(new Error(`GraphQL Error: ${queryName}`));
        });

        if (reason.includes("NoValidAuthTokens")) {
          // Nasty hack to force a redirect to login page when the user's access token has expired
          router.push("/login");
        }

        queryError.current = reason;
        onError?.(reason);
      }
    },
  });

  return {
    data: (queryData || {}) as Data,
    error: Utils.isNotEmpty(queryError.current) ? queryError.current : null,
    isLoading,
    isFetching,
  };
};

export default useQuery;
