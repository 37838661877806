import { graphql } from "msw";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const verifyHandlers = [
  graphql.query("requestCode", (req, res, ctx) => {
    return res(
      ctx.data({
        requestCode: {
          sid: uuidv4(),
          to: req.variables.To,
          status: "pending",
        },
      }),
    );
  }),

  graphql.query("verifyCode", (req, res, ctx) => {
    return res(
      ctx.data({
        verifyCode: {
          sid: uuidv4(),
          to: req.variables.To,
          status: "approved",
        },
      }),
    );
  }),

  graphql.query("cancelCode", (req, res, ctx) => {
    return res(
      ctx.data({
        cancelCode: {
          sid: uuidv4(),
          to: req.variables.To,
          status: "pending",
        },
      }),
    );
  }),
];
