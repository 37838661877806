import React from "react";
import { createContext, useContext } from "react";
// At this stage do not replace function (useSyncExternalStore) with the same that is coming from react v18+
// because they have issue with twice calling of the snapshot!
import { useSyncExternalStore } from "use-sync-external-store/shim";
import usePubSubStore from "../hooks/usePubSubStore";
import { ToastProvider } from "./ToastProvider";

import type { ConfirmSignInOutput, SignInOutput } from "aws-amplify/auth";
import type { Client, Trade } from "src/graphql/gql-types";
import type { ISignInFormData } from "../components/auth-form/SignInForm";

export interface ISignInStage {
  source: string | null;
  formData: ISignInFormData;
  authData: SignInOutput | ConfirmSignInOutput;
  authState: string;
}

export type AmplifyUser = {
  email: string;
  phone_number?: string;
};

export interface IAppContext {
  isSidebarOpened: boolean;
  shouldSeeSelectTradeButton: boolean;
  clients: Client[];
  tradeHistoryItems: Trade[];
  activeClient: Client | null;
  amplifyUser: AmplifyUser | null;
  signIn: ISignInStage | null;
}

type TAppType<SelectorOutput> = [
  SelectorOutput,
  (value: Partial<IAppContext>) => void,
];

export type TPubSubType = ReturnType<typeof usePubSubStore<IAppContext>>;

export const AppContext = createContext<TPubSubType | null>(null);

const AppProvider = ({ children }) => {
  const appStore: TPubSubType = usePubSubStore<IAppContext>({
    shouldSeeSelectTradeButton: true,
  } as IAppContext);

  return (
    <AppContext.Provider value={appStore}>
      <ToastProvider>{children}</ToastProvider>
    </AppContext.Provider>
  );
};

function useApp<SelectorOutput>(
  selector: (store: IAppContext) => SelectorOutput,
): TAppType<SelectorOutput> {
  const store = useContext(AppContext);

  if (!store) throw new Error("Couldn't fine the store");

  //@see: https://github.com/reactwg/react-18/discussions/86
  //@see: https://beta.reactjs.org/apis/react/useSyncExternalStore
  const state = useSyncExternalStore(
    store.subscribe,
    () => selector(store.get()),

    //@see: https://beta.reactjs.org/apis/react/useSyncExternalStore#adding-support-for-server-rendering
    () => selector({} as IAppContext),
  );

  return [state, store.set];
}

export { useApp };
export default AppProvider;
