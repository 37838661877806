import { graphql } from "msw";
import { Client } from "src/graphql/gql-types";
import clients from "../data/clients.json";

export const clientHandlers = [
  graphql.query("getClient", (req, res, ctx) => {
    return res(
      ctx.data({
        getClient: clients.find((item) => item.cli_id === req.variables.id),
      }),
    );
  }),

  graphql.query("getClients", (req, res, ctx) => {
    let returnedClients = [];

    // In test we can filter for email
    const testClients = clients.filter(
      (client) => client.cli_email === req.body.variables.cli_email,
    );

    if (testClients.length) {
      returnedClients = testClients;
    } else {
      // Return 2 clients
      returnedClients = clients.filter(
        (client) => client.cli_email === "test1@test.com",
      );
    }

    return res(
      ctx.data({
        getClients: returnedClients,
      }),
    );
  }),

  graphql.mutation("CreateClient", (req, res, ctx) => {
    const data = req.variables as Client;

    (clients as Client[]).push(data);

    return res(
      ctx.data({
        CreateClient: data,
      }),
    );
  }),
];
