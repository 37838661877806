import { graphql } from "msw";
import { BookTradeResult, Trade, TradeStatus } from "src/graphql/gql-types";
import trades from "../../mocks/data/trades.json";

export const bookTradeResponse = (): BookTradeResult => {
  const id = Math.floor(Math.random() * 1000).toString(),
    trade_date = "20200101",
    value_date = "20200101",
    sold_amount = 500.0,
    sold_currency = "GBP",
    bought_amount = 599.85,
    bought_currency = "EUR",
    account_name = "Clear Treasury Ltd",
    bank_name = "Barclays",
    account_number = "31510604",
    bank_address = "Barclays Bank \r\n1 Churchill Place, London E14 5HP",
    iban = "GB24BARC20060503049108",
    swift_code = "BARCGB22",
    sort_code = "200605",
    reference = "0000001";

  return {
    trade: {
      id,
      reference,
      trade_date,
      value_date,
      sold_amount,
      sold_currency,
      bought_amount,
      bought_currency,
      converted: 599.85,
      funds_received: 0,
      outstanding_amount: 0,
      quote_rate: 1,
      settled: false,
      status: TradeStatus.Pending,
      payment_details: {
        account_name,
        bank_name,
        account_number,
        bank_address,
        iban,
        swift_code,
        sort_code,
      },
    } as Trade,
  };
};

export const tradeHandlers = [
  graphql.mutation("bookTrade", (req, res, ctx) => {
    return res(
      ctx.data({
        bookTrade: bookTradeResponse(),
      }),
    );
  }),

  graphql.query("getTrades", (req, res, ctx) => {
    return res(
      ctx.data({
        getTrades: {
          totalCount: trades.length + 1,
          trades,
        },
      }),
    );
  }),
];
