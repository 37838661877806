import { graphql } from "msw";
import beneficiaries from "../data/beneficiaries.json";

export const beneficiaryHandlers = [
  graphql.mutation("createBeneficiary", (req, res, ctx) => {
    beneficiaries.push({
      ...req.variables.input,
      id: `${Date.now()}`,
    });

    return res(
      ctx.data({
        createBeneficiary: {
          id: "1",
          message: "Beneficiary added to client's list",
        },
      }),
    );
  }),

  graphql.query("verifyBeneficiary", (req, res, ctx) => {
    const response = {
      answer: "full_match",
      actual_name: "Ricardo Sousa",
      reason: "Full Match",
      reason_type: "okay",
      reason_code: "AV100",
    };

    if (
      req.body.variables.beneficiary_type === "individual" &&
      req.body.variables.beneficiary_last_name === "Sous"
    ) {
      response.answer = "close_match";
      response.reason = "String is a close match to the account name.";
      response.reason_type = "warning";
      response.reason_code = "AV300";
    }

    if (
      req.body.variables.beneficiary_type === "individual" &&
      !req.body.variables.beneficiary_last_name.includes("Sous")
    ) {
      response.answer = "no_match";
      response.actual_name = null;
      response.reason = "Unable to check account details.";
      response.reason_type = "rejected";
      response.reason_code = "AV200";
    }

    return res(
      ctx.data({
        verifyBeneficiary: response,
      }),
    );
  }),

  graphql.query("getBeneficiaries", (req, res, ctx) => {
    const clientBeneficiaries = beneficiaries.filter(
      (beneficiary) => beneficiary.client_ref === req.body.variables.client_ref,
    );

    const sanitizedBeneficiaries = clientBeneficiaries.map((b) => {
      const { client_ref, ...rest } = b;
      return rest;
    });

    return res(
      // ctx.delay(3000),
      ctx.data({
        getBeneficiaries: { beneficiaries: sanitizedBeneficiaries },
      }),
    );
  }),
];
