import { graphql } from "msw";
import transfers from "../data/transfers.json";

export const transferHandlers = [
  graphql.query("getTransfersV2", (req, res, ctx) => {
    let copiedData = [...transfers];

    const { currency, filter, limit } = req.variables;
    const totalCount = copiedData.length * 10;
    const rangeLimit = Math.min(totalCount, Math.abs(limit));

    function findItem(arr, searchKey) {
      return arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
          if (obj[key]) {
            return `${obj[key]}`.includes(searchKey);
          }
          return false;
        });
      });
    }

    if (currency) {
      copiedData = findItem(copiedData, currency);
    }

    if (filter) {
      copiedData = findItem(copiedData, filter);
    }

    return res(
      ctx.data({
        getTransfersV2: {
          totalCount: totalCount,
          nextToken: "MTExMzMzOjMwODg3OjkwMDcxOTkyNTQ3NDA5OTE=",
          transfers: copiedData.slice(0, rangeLimit),
        },
      }),
    );
  }),
];
