import { graphql } from "msw";

export const randomInRange = (min: number, max: number) => {
  return Math.random() < 0.5
    ? (1 - Math.random()) * (max - min) + min
    : Math.random() * (max - min) + min;
};

export const quoteHandlers = [
  graphql.query("getQuote", (req, res, ctx) => {
    const { currency_buy, currency_sell, value_date } = req.variables;
    const { sell_amount, buy_amount } = req.variables;

    const quote_rate = randomInRange(1.0, 1.5);
    const uuidV4 = `bc0f3f71-304b-x${Math.floor(
      Math.random() * 1000,
    )}y-b200-034237baa8eb`;

    if (buy_amount) {
      return res(
        ctx.data({
          getQuote: {
            ID: uuidV4,
            sell_amount: buy_amount * quote_rate,
            buy_amount,
            quote_rate,
            value_date,
            currency_buy,
            currency_sell,
          },
        }),
      );
    }

    return res(
      ctx.data({
        getQuote: {
          ID: uuidV4,
          sell_amount,
          buy_amount: sell_amount * quote_rate,
          quote_rate,
          value_date,
          currency_buy,
          currency_sell,
        },
      }),
    );
  }),
];
