import { graphql } from "msw";
import { Balance } from "src/graphql/gql-types";
import balances from "../data/balances.json";

export const balanceHandlers = [
  graphql.query<{ getBalances: Balance[] }>("getBalances", (_req, res, ctx) => {
    return res(
      ctx.data({
        getBalances: balances,
      }),
    );
  }),
];
