export function setCookie(
  name: string,
  value: string,
  days?: number,
  expired?: boolean,
) {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  if (expired) {
    expires = `; expires=0`;
  }

  if (typeof window !== "undefined") {
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }
}
