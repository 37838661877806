import { Trade } from "src/graphql/gql-types";

export interface BankHolidays {
  "england-and-wales": RegionalEvents;
  scotland: RegionalEvents;
  "northern-ireland": RegionalEvents;
}

export interface RegionalEvents {
  division: string;
  events: Event[];
}

export interface Event {
  title: string;
  date: Date;
  notes: Notes;
  bunting: boolean;
}

export enum Notes {
  Empty = "",
  SubstituteDay = "Substitute day",
}

export default class Utils {
  public static debounce(
    callback: (args: any) => void,
    wait = 300,
  ): (...args: any) => void {
    let timeoutID: NodeJS.Timeout;

    return (...args) => {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => callback.apply(this, args), wait);
    };
  }

  public static hasHorizontalScrollBar($element: HTMLElement): boolean {
    if (typeof HTMLElement !== "object" && !($element instanceof HTMLElement))
      return false;

    return $element.scrollWidth > $element.clientWidth;
  }

  public static hasVerticalScrollBar($element: HTMLElement): boolean {
    if (typeof HTMLElement !== "object" && !($element instanceof HTMLElement))
      return false;

    return $element.scrollHeight > $element.clientHeight;
  }

  public static parseAmountByCurrencyCode(
    amount: number | string,
    currency: string,
    locale = "en-GB",
  ): string {
    const x: number = typeof amount === "string" ? Number(amount) : amount;

    return Math.abs(x).toLocaleString(locale, {
      currency: currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  public static isEmpty(value: unknown): boolean {
    if (value === null || value === undefined) return true;

    if (Array.isArray(value)) return value.length === 0;

    if (typeof value === "object") {
      return Object.keys(value).length === 0;
    }

    if (typeof value === "string") return value.length === 0;
  }

  public static isNotEmpty(value: unknown): boolean {
    if (value === null || value === undefined) return false;

    if (Array.isArray(value)) return value.length > 0;

    if (typeof value === "object") return Object.keys(value).length > 0;

    if (typeof value === "string") return value.length > 0;

    return true;
  }

  public static getAllSettledTrades(
    trades: Trade[],
    currency: string,
  ): Trade[] {
    const filteredTrades: Trade[] = trades.filter((trade: Trade) => {
      const sumOfpaymentsAmounts: number = trade.payments.reduce(
        (acc, curr) => (acc += Number(curr.amount)),
        0,
      );
      return (
        trade.settled &&
        trade.bought_currency === currency &&
        trade.bought_amount > sumOfpaymentsAmounts
      );
    });

    return filteredTrades;
  }

  public static getUnderInstructedTradesByCurrency(
    trades: Trade[],
    currency: string,
  ): Trade[] | [] {
    return trades.filter((t) => {
      const paymentsTotal = t.payments.reduce(
        (acc, curr) => acc + parseFloat(curr.amount),
        0,
      );
      return t.bought_currency === currency && paymentsTotal < t.bought_amount;
    });
  }

  public static async getBankHolidays(): Promise<Array<Date>> {
    const govData: BankHolidays = await fetch(
      "https://www.gov.uk/bank-holidays.json",
    ).then((res) => res.json());
    return govData["england-and-wales"].events.map(
      (event) => new Date(event.date),
    );
  }
}
